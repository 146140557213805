.App{
  width: 100%;
  height: 100%;
}

html, body {
  overscroll-behavior: none; /* Prevent rubber banding */
}

/* Set 100% height for html and body */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlayBox{
  width:85%;
  height:85%;
  border: 3px solid lightgray;
  background:#fff;
  padding: 25px;
  overflow-y: auto;
}

.refine-btn {
  background-color: white;
  color: #6557F5;
  border: 2px solid #c8c3fb;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.refine-btn:hover {
  background-color: #6557F5;
  color: white;
}
.refine-btn svg {
  fill: #6557F5;
}
.refine-btn:hover svg {
  fill: white;
}
